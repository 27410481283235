@media screen and (max-width: 480px) {
  /* 在小屏幕的时候可以有更好的体验 */
  .umi-plugin-layout-container {
    width: 100% !important;
  }
  .umi-plugin-layout-container > * {
    border-radius: 0 !important;
  }
}
.umi-plugin-layout-menu .anticon {
  margin-right: 8px;
}
.umi-plugin-layout-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.umi-plugin-layout-right {
  display: flex !important;
  float: right;
  height: 100%;
  margin-left: auto;
  overflow: hidden;
}
.umi-plugin-layout-right .umi-plugin-layout-action {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 12px;
  cursor: pointer;
  transition: all 0.3s;
}
.umi-plugin-layout-right .umi-plugin-layout-action > i {
  color: rgba(255, 255, 255, 0.85);
  vertical-align: middle;
}
.umi-plugin-layout-right .umi-plugin-layout-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.umi-plugin-layout-right .umi-plugin-layout-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.umi-plugin-layout-right .umi-plugin-layout-search {
  padding: 0 12px;
}
.umi-plugin-layout-right .umi-plugin-layout-search:hover {
  background: transparent;
}
.umi-plugin-layout-name {
  margin-left: 8px;
}
.umi-plugin-layout-name.umi-plugin-layout-hide-avatar-img {
  margin-left: 0;
}
